.App {
	display: flex;
	padding-top: 200px;
	min-height: 100vh;
	min-height: 100dvh;
	max-width: 100vw;
	max-width: 100dvw;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper,
.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
	background-color: transparent !important;
	box-shadow: none !important;
}
@media print {
	.no-print {
		display: none;
	}
}
[dir="rtl"] {
	svg[data-testid="EastOutlinedIcon"],
	svg[data-testid="LoginOutlinedIcon"],
	svg[data-testid="ArrowBackIosOutlinedIcon"],
	svg[data-testid="ArrowForwardIosOutlinedIcon"],
	svg[data-testid="EastIcon"],
	svg[data-testid="NavigateBeforeIcon"],
	svg[data-testid="NavigateNextIcon"] {
		transform: rotate(180deg);
	}
}
